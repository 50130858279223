import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.scss";
import Header from "./header/header";
import { Homepage } from "./views/homepage/homepage";
import { PrivacyPolicy } from "./views/privacyPolicy/privacyPolicy";

type State = {
  fileKeys: string[];
};

export class App extends React.Component<{}, State> {
  componentDidMount() {
    const markdownFiles = require.context(
      "content/caseStudies",
      false,
      /\.\/.*md/i
    );

    this.setState({
      fileKeys: markdownFiles.keys(),
    });
  }

  render() {
    if (!this.state) return;

    return (
      <div className="App">
        <BrowserRouter>
          <Header />
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/Home" element={<Homepage />} />
            <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
          </Routes>
        </BrowserRouter>
      </div>
    );
  }
}
