import React from "react";
import "./homepage.scss";
type State = {};

export class Homepage extends React.Component<{}, State> {
  async componentDidMount() {}

  render() {
    return (
      <div className="Homepage_root">
        <div className="w-screen h-screen flex items-center flex-col overflow-x-hidden">
          <div className="flex-[1] flex flex-col justify-center pt-20">
            <h3>Sono &amp; Su - Fishing for Treasure </h3>
          </div>
          <div className="flex-[3] flex flex-col justify-center">
            <h2>Coming Soon</h2>
          </div>
          <div className="flex-[1] flex flex-col justify-center items-center">
            <h5>KVK: 90429419</h5>
            <h5>DUNS: 494737333</h5>
            <h5 className="text-center mt-10">
              © {new Date().getFullYear()} Noomi Studios
            </h5>
          </div>
        </div>
      </div>
    );
  }
}
